// i18next-extract-mark-ns-start payment-gateway

import {Button, ButtonLink} from 'components/Button';
import {Content} from 'components/Content';
import {HomePartners} from 'components/HomePartners';
import {HomePaymentMethods} from 'components/HomePaymentMethods';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import index1 from 'images/payment_gateway.svg';
import payment from 'images/payment.svg';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {Clients, defaultClientList} from 'components/Clients';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import {StyledDiv, StyledImg, StyledP} from 'components/StyledSx';
import {ScreenSizes} from '../../../types/responsive';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {GoogleReviews, Place} from 'components/GoogleReviews';
import {Background} from 'components/Background';
import {SupportLink} from 'components/links/Support';
import megaphone from 'images/megaphone.svg';
import trading from 'images/subscriptions_reasons_3.svg';
import coinstack from 'images/coinstack.svg';
import click1 from 'images/click1.svg';
import shopify_payment_gateway from 'images/shopify_payment_gateway.svg';
import recurring_payments from 'images/recurring_payments.svg';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import {FaqsSection} from 'components/FaqsSection';
import {List} from 'components/List';
import {LearnMoreLinks} from 'components/LearnMoreLinks';
import {Helmet} from 'react-helmet';
import moneiLogo from 'images/monei_logo.svg';
import {HubspotForm} from 'components/HubspotForm';

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const IndexBackground = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: -8.9%;
    height: 1256px;
    width: 1203px;
    background: linear-gradient(353.74deg, #41efd6 -47.57%, #aab4e9 13.85%);
    transform: matrix(2, 0, 0, -1, 1248, 4) rotate(47deg) skew(7deg, 1.6deg);
    @media (max-width: 1024px) {
      top: -11.5%;
      transform: matrix(2, 0, 0, -1, 1185, 25) rotate(44.5deg) skew(7deg, 1.6deg);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(34deg) matrix(1, 0, 0, -1, 459, -315);
    left: 50%;
    top: -3%;
    opacity: 1;
    height: 972px;
    width: 1289px;
    background: linear-gradient(353.74deg, #41efd6 -41.57%, #aab4e9 53.85%);
    @media (max-width: 1024px) {
      top: -13%;
      transform: rotate(34deg) matrix(1, 0, 0, -1, 470, -240);
    }
    @media (max-width: 768px) {
      content: none;
    }
  }
`;

const IndexImage = styled.img`
  position: absolute;
  left: 56%;
  top: 20px;
  width: 540px;
  z-index: 9;

  @media (max-width: 1024px) {
    left: 60%;
    width: 45%;
    top: 1%;
  }
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 0;
  max-width: 550px;
  min-height: 600px;
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 500px;
    min-height: 510px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    max-width: 100%;
    min-height: 410px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const PaymentGateway: React.FC<PageProps> = (props: any) => {
  const {t} = useI18next();

  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const clientsList = defaultClientList.slice(0, 5).map((client) => {
    return {
      ...client,
      width: 180 * client.scale
    };
  });

  const faqs = [
    {
      header: '¿Qué es una pasarela de pago?',
      text: t(
        'Las pasarelas de pago son una tecnología de pago segura que te permite aceptar pagos online con tarjeta de crédito y otros métodos de pago como Apple Pay, Google Pay y Bizum.'
      ),
      content:
        'Las pasarelas de pago son una tecnología de pago segura que te permite aceptar pagos online con tarjeta de crédito y otros métodos de pago como Apple Pay, Google Pay y Bizum.'
    },
    {
      header: '¿Cómo funciona una pasarela de pago?',
      text: 'Una pasarela de pago se integra con tu sitio web de e-commerce y se comunica con el adquirente, el emisor y el procesador durante las transacciones online para aprobar o rechazar los pagos. A continuación, deposita los fondos para las transacciones aprobadas en tu cuenta bancaria.',
      content:
        'Una pasarela de pago se integra con tu sitio web de e-commerce y se comunica con el adquirente, el emisor y el procesador durante las transacciones online para aprobar o rechazar los pagos. A continuación, deposita los fondos para las transacciones aprobadas en tu cuenta bancaria.'
    },
    {
      header: '¿Por qué necesito una pasarela de pago?',
      text: 'Sin una pasarela de pago online, no tendrás forma de aceptar pagos de forma segura en tu tienda de e-commerce.',
      content:
        'Sin una pasarela de pago online, no tendrás forma de aceptar pagos de forma segura en tu tienda de e-commerce.'
    },
    {
      header: '¿Cuáles son los tipos de pasarelas de pago?',
      text: t(
        `- Pasarela de pago alojada. - Pasarela de pago autohospedada. - Pasarela de pago integrada o alojada en una API. - Pasarela de integración bancaria local.`
      ),
      content: (
        <List sx={{marginTop: '12px'}}>
          <li>Pasarela de pago alojada</li>
          <li>Pasarela de pago autohospedada</li>
          <li>Pasarela de pago integrada o alojada en una API</li>
          <li>Pasarela de integración bancaria local</li>
        </List>
      )
    }
  ];

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>Pasarela de pago: acepte pagos en línea</title>
        <meta
          name="description"
          content="La pasarela de pago de MONEI es segura, asequible y se integra fácilmente con su sitio web de e-commerce. Acepta más métodos de pago y aumenta las ventas. Empieza ahora ››"
        />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <IndexBackground>
        <Content>
          <Section
            centered
            sx={{marginTop: '1.875rem', flexDirection: {md: 'column'}, gap: '90px'}}>
            <IndexContent>
              <StyledImg src={moneiLogo} sx={{width: '300px', marginBottom: '54px'}} />
              <LargeSectionHeader underline tagName="h1">
                La mejor pasarela de pagos para hacer crecer tu negocio online
              </LargeSectionHeader>
              Acepta pagos online de consumidores en España y a través de las fronteras. Elige MONEI
              para una integración de pasarela de pago de e-commerce que te permita aceptar todos
              los métodos de pago más importantes, incluidas tarjetas, billeteras digitales y
              métodos de pago locales.
            </IndexContent>
            <HubspotForm formId="1e6951bf-0cc8-4439-922b-f33a2ee988f5" minHeight={338} />
          </Section>
          <Clients clientList={clientsList} />
        </Content>
      </IndexBackground>

      <Content>
        <Section textAlign="center">
          <div>
            <div style={{marginBottom: 122}}>
              <SectionHeader underline>
                Acepta más métodos de pago con nuestra pasarela de pago
              </SectionHeader>
              Aumenta las conversiones, llega a más clientes y mejora la experiencia de pago
              aceptando métodos de pago populares, como tarjetas de crédito y débito, Google Pay,
              Apple Pay, y métodos de pago locales, como Bizum, Multibanco, entre otros.
              <StyledDiv sx={{marginTop: '30px'}}>
                <HomePaymentMethods />
              </StyledDiv>
            </div>
          </div>
        </Section>
      </Content>
      <Content>
        <Section textAlign="center" sx={{paddingTop: '0'}}>
          <div>
            <SectionHeader sx={{width: '100%'}} underline>
              Integración rápida y sencilla de la pasarela de pago
            </SectionHeader>
            Conecta fácilmente tu sitio web de e-commerce con la pasarela de pago de MONEI. Usa
            complementos para Shopify, WooCommerce, Wix, Magento (Adobe Commerce), y PrestaShop o
            intégralos con un sitio web personalizado mediante nuestra API de pagos.
            <HomePartners />
            <SectionActions align="center" style={{marginBottom: 100}}>
              <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
                Contactar con ventas
              </Button>
            </SectionActions>
          </div>
        </Section>
      </Content>
      <Background>
        <Content>
          <Section centered sx={{paddingTop: {sm: '100px'}}}>
            <StyledDiv>
              <SectionHeader>Seguridad y cumplimiento de las pasarelas de pago</SectionHeader>
              La seguridad es clave. Tu pasarela de pago no debería ser un eslabón débil en el
              proceso de transacción y debería ayudarte a evitar el fraude y las devoluciones de
              cargos. Nuestra pasarela de pago cumple con 3D Secure 2.0, PSD2, y PCI DSS Level 1 por
              lo que nunca tendrás que preocuparte por los problemas de seguridad de los pagos.
              <SectionActions align="left">
                <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
                  Contactar con ventas
                </Button>
              </SectionActions>
            </StyledDiv>
            <SectionImage
              style={{width: '70%'}}
              mobileWidth={370}
              src={shopify_payment_gateway}
              alt="Scan to Pay"
              title="Scan to Pay"
              className="hide-on-mobile"
            />
          </Section>
        </Content>
      </Background>
      <Content>
        <Section column centered sx={{textAlign: 'center'}}>
          <div>
            <SectionHeader sx={{width: '100%'}} underline>
              ¿Por qué elegir MONEI como tu pasarela de pagos online?
            </SectionHeader>
            Evita el dolor de cabeza de obtener un TPV virtual de tu banco. Intégrate con MONEI para
            incorporar la adquisición comercial y la posibilidad de aceptar pagos internacionales.
            Embárcate en cinco minutos para disfrutar de estas funciones y mucho más.
          </div>

          <SectionActions align="center">
            <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
              Contactar con ventas
            </Button>
          </SectionActions>
          <CardsContainer sx={{marginTop: '60px'}}>
            <Card>
              <CardImage src={trading} alt="" style={{width: 100, top: -35}} />
              <CardWrapper>
                Gestiona toda tu cartera de pagos desde una única plataforma
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={megaphone} alt="" style={{width: 105, top: -40}} />
              <CardWrapper>
                Aumenta la confianza y el conocimiento de tu marca con una página de pago
                personalizada
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={payment} alt="" style={{width: 105, top: -40}} />
              <CardWrapper>
                Maximiza las tasas de aceptación de transacciones con la orquestación de pagos
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={coinstack} alt="" style={{width: 95, top: -40}} />
              <CardWrapper>
                Ahorra dinero en comisiones de transacción con precios dinámicos y disfruta de
                liquidaciones rápidas
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={recurring_payments} alt="" style={{width: 110, top: -40}} />
              <CardWrapper>
                Obtén ingresos recurrentes con planes de suscripción flexibles
              </CardWrapper>
            </Card>
            <Card>
              <CardImage src={click1} alt="" style={{width: 105, top: -40}} />
              <CardWrapper>Acepta pagos con o sin un sitio web mediante Pay By Link</CardWrapper>
            </Card>
          </CardsContainer>
        </Section>
      </Content>
      <Content sx={{marginTop: '100px'}}>
        <Section column centered sx={{textAlign: 'center'}}>
          <SectionHeader underline>
            Más información sobre la pasarela de pago de MONEI
          </SectionHeader>
          <LearnMoreLinks />
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            ¿Tienes una plataforma empresarial o un mercado?
          </SectionHeader>
          <GradientSectionDescription>
            Ya sea que tengas una plataforma o un mercado para marcas, restaurantes, autónomos,
            taxis u otro sector, usa MONEI Connect para integrar los pagos y ayudar a tus usuarios a
            aceptar más métodos de pago online, en persona o mientras viajan.
          </GradientSectionDescription>

          <SectionActions align="left">
            <Button variant="dark" style={{marginRight: 15}} onClick={handleScrollToTop}>
              Contactar con ventas
            </Button>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%'
            }}
            src={integrated_payments_header_image}
          />
        </GradientBox>
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Payment gateway FAQ</Trans>} />
        </Section>
      </Content>

      <Content>
        <Section textAlign="center">
          <div>
            <SectionHeader underline>¿Aún sigues aquí?</SectionHeader>
            <p>Prueba MONEI ahora</p>
            <SectionActions align="center" style={{marginBottom: 100}}>
              <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
                Contactar con ventas
              </Button>
            </SectionActions>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default PaymentGateway;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
